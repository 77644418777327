import { ScheduleInterviewFormInputs } from '@/validation/types';

type FormField<T> = {
  labelText: string;
  labelDescription?: string;
  labelFor: T;
  id: T;
  name: T;
  type: 'select' | 'text' | 'date' | 'time' | 'checkbox';
  autoComplete: T;
  required: boolean;
  placeholder: string;
};

const scheduleInterviewFormFields: Array<FormField<keyof ScheduleInterviewFormInputs>> = [
  {
    labelText: 'Is this interview in-person?',
    labelFor: 'isInPerson',
    id: 'isInPerson',
    name: 'isInPerson',
    type: 'checkbox',
    autoComplete: 'isInPerson',
    required: true,
    placeholder: 'Is this interview in-person?',
  },
  {
    labelText: 'Day',
    labelDescription:
      'Select the days of the week you would typically be available for  interviews.',
    labelFor: 'day',
    id: 'day',
    name: 'day',
    type: 'date',
    autoComplete: 'day',
    required: true,
    placeholder: 'Select day',
  },
  {
    labelText: 'From',
    labelFor: 'from',
    id: 'from',
    name: 'from',
    type: 'time',
    autoComplete: 'from',
    required: true,
    placeholder: 'eg. 9AM',
  },
  {
    labelText: 'To',
    labelFor: 'to',
    id: 'to',
    name: 'to',
    type: 'time',
    autoComplete: 'to',
    required: true,
    placeholder: 'eg. 12PM',
  },
  {
    labelText: 'Duration',
    labelFor: 'duration',
    id: 'duration',
    name: 'duration',
    type: 'select',
    autoComplete: 'duration',
    required: true,
    placeholder: 'eg. 15mins',
  },
  {
    labelText: 'Meeting Link',
    labelFor: 'meetingLink',
    id: 'meetingLink',
    name: 'meetingLink',
    type: 'text',
    autoComplete: 'meetingLink',
    required: true,
    placeholder: 'Add meeting link',
  },
  {
    labelText: 'Meeting Location',
    labelFor: 'meetingLocation',
    id: 'meetingLocation',
    name: 'meetingLocation',
    type: 'text',
    autoComplete: 'meetingLocation',
    required: true,
    placeholder: 'Add meeting location',
  },
];

// eslint-disable-next-line import/prefer-default-export
export { scheduleInterviewFormFields };
