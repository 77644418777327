'use client';
/* eslint-disable max-lines */
import convertTime from 'convert-time';
import { useCallback, useEffect, useState } from 'react';
import { apiUrl } from '@/api.config';
import {
  GetAvailabilitiesResData,
  InterviewAvailability,
} from '@/components/Modals/jobs/schedule-interview/types';
import Notify from '@/utils/Notify';
import { PostAsync } from '@/utils/Post';
import useProcessAsync from '@/hooks/useProcessAsync';
import { HiringProcessCategory } from '@/modules/Jobs/types/jobTitlePipeline';
import { useAppSelector } from '@/store/hooks';
import { selectApplicationsState, selectAuthState } from '@/store/selectors';
import { ScheduleInterviewFormInputs } from '@/validation/types';
import useQueryParams from '@/hooks/useQueryParams';

// type Params = {};

export default function useScheduleInterview() {
  const { query, router } = useQueryParams();
  const [interviewAvailabilities, setInterviewAvailabilities] = useState<InterviewAvailability[]>(
    []
  );
  const [showInterviewForm, setShowInterviewForm] = useState(false);
  const [deletingId, setDeletingId] = useState('');
  const { processAsync: getAvailabilities, isLoading: isGettingAvailabilities } = useProcessAsync();
  const { processAsync: addAvailability, isLoading: isAddingAvailability } = useProcessAsync();
  const { processAsync: updateAvailability, isLoading: isUpdatingAvailability } = useProcessAsync();
  const { processAsync: deleteAvailability, isLoading: isDeletingAvailability } = useProcessAsync();
  const { processAsync: sendInterviewRequest, isLoading: isSendingInterviewRequest } =
    useProcessAsync();
  const [availabilityUpdate, setAvailabilityUpdate] = useState<InterviewAvailability | null>(null);
  const { user } = useAppSelector(selectAuthState);
  const { previewJobId } = useAppSelector(selectApplicationsState);
  const jobId = query?.jobId || previewJobId;

  const changeTabToInterviews = useCallback(() => {
    const tabName: HiringProcessCategory = 'interviews';
    const url = new URL(window.location.href);
    url.searchParams.delete('filter[stage]');
    url.searchParams.set('filter[stage]', tabName);
    router.push(url.href);
  }, [router]);

  const showUpdateAvailabilityForm = useCallback(
    async (availabilityId: string) => {
      const currentAvailability = interviewAvailabilities.find((ia) => ia.id === availabilityId);
      if (currentAvailability) {
        setAvailabilityUpdate(currentAvailability);
      }
    },
    [interviewAvailabilities]
  );

  /**
   * Retrieves the available schedules for the candidate application
   *
   */
  const handleGetInitialAvailabilities = useCallback(async () => {
    const res = await getAvailabilities(async () => {
      return PostAsync({
        url: `${apiUrl}/jobs/${jobId}/availability`,
        method: 'GET',
        token: user?.token,
      });
    });

    if (res?.meta?.status === 'success') {
      const resData = res.data as GetAvailabilitiesResData['data'];

      const fetchedAvailableDays: InterviewAvailability[] = resData?.map((item) => {
        const { attributes: av, id } = item || {};
        const fromTime = new Date(av.starts_at).toLocaleTimeString('en-Us', { timeStyle: 'short' });
        const toTime = new Date(av.ends_at).toLocaleTimeString('en-Us', {
          timeStyle: 'short',
        });

        return {
          id,
          isInPerson: av?.is_in_person || false,
          day: new Date(av?.starts_at || av?.ends_at).toDateString(),
          duration: `${av?.duration_in_minutes}`,
          meetingLink: av?.meeting_link || '',
          meetingLocation: av?.meeting_location || '',
          period: `${fromTime} - ${toTime}`,
          from: fromTime,
          to: toTime,
          startDate: av.starts_at,
          endDate: av.ends_at,
        };
      });

      if (!fetchedAvailableDays.length) {
        setShowInterviewForm(true);
      }
      setInterviewAvailabilities([...fetchedAvailableDays].reverse());
    }
  }, [getAvailabilities, jobId, user?.token]);

  /**
   * adds an interview availability
   *
   */
  const handleAddAvailableDay = useCallback(
    async (newAvailability: ScheduleInterviewFormInputs) => {
      if (!newAvailability) {
        return;
      }
      const startDate = `${newAvailability.day} ${newAvailability.from}` as unknown as Date;
      const endDate = `${newAvailability.day} ${newAvailability.to}` as unknown as Date;
      const postBody = {
        starts_at: startDate,
        ends_at: endDate,
        duration_in_minutes: +newAvailability.duration,
        buffer_in_minutes: +newAvailability.duration - 5,
        meeting_link: newAvailability.meetingLink,
        meeting_location: newAvailability.meetingLocation,
        is_in_person: newAvailability.isInPerson,
      };
      const res = await addAvailability(async () => {
        return PostAsync({
          url: `${apiUrl}/jobs/${jobId}/add-availability`,
          method: 'POST',
          token: user?.token,
          body: postBody,
        });
      });

      if (res?.meta?.status === 'success') {
        setInterviewAvailabilities((p) => [
          {
            id: res?.data?.id || p.length?.toString(),
            day: new Date(newAvailability?.day).toDateString(),
            duration: newAvailability?.duration,
            meetingLink: newAvailability?.meetingLink,
            isInPerson: newAvailability?.isInPerson,
            meetingLocation: newAvailability?.meetingLocation,
            period: `${convertTime(newAvailability?.from)} to ${convertTime(newAvailability?.to)}`,
            from: newAvailability?.from,
            to: newAvailability?.to,
            startDate,
            endDate,
          },
          ...p,
        ]);
        Notify.success('Availability added successfully', 'Added', undefined, 5_000);
      } else {
        Notify.error('Availability could not be added. Please try again later', 'Not Added');
      }

      // setShowInterviewForm(false);
    },
    [addAvailability, jobId, user?.token]
  );

  /**
   * Updates an interview availability
   *
   */
  const handleUpdateAvailableDay = useCallback(
    async (
      updatedAvailability: ScheduleInterviewFormInputs,
      onMeetingReschedule?: (updatedAvailability: InterviewAvailability) => void
    ) => {
      if (!updatedAvailability) {
        return;
      }
      const startDate = `${updatedAvailability.day} ${updatedAvailability.from}` as unknown as Date;
      const endDate = `${updatedAvailability.day} ${updatedAvailability.to}` as unknown as Date;
      const postBody = {
        starts_at: startDate,
        ends_at: endDate,
        duration_in_minutes: +updatedAvailability.duration,
        buffer_in_minutes: +updatedAvailability.duration - 5,
        meeting_link: updatedAvailability.meetingLink,
        meeting_location: updatedAvailability.meetingLocation,
        is_in_person: updatedAvailability.isInPerson,
      };
      const res = await updateAvailability(async () => {
        return PostAsync({
          url: `${apiUrl}/jobs/${jobId}/update-availability/${availabilityUpdate?.id || ''}`,
          method: 'PUT',
          token: user?.token,
          body: postBody,
        });
      });

      if (res?.meta?.status === 'success') {
        const update = {
          id: availabilityUpdate?.id || '',
          day: new Date(updatedAvailability?.day).toDateString(),
          duration: updatedAvailability?.duration,
          meetingLink: updatedAvailability?.meetingLink,
          isInPerson: updatedAvailability?.isInPerson,
          meetingLocation: updatedAvailability?.meetingLocation,
          period: `${convertTime(updatedAvailability?.from)} to ${convertTime(
            updatedAvailability?.to
          )}`,
          from: updatedAvailability?.from,
          to: updatedAvailability?.to,
          startDate,
          endDate,
        };
        setInterviewAvailabilities((p) =>
          p.map((ia) => {
            if (availabilityUpdate && ia.id === availabilityUpdate.id) {
              return update;
            }
            return ia;
          })
        );
        setAvailabilityUpdate(null);
        if (onMeetingReschedule) {
          onMeetingReschedule(update);
        }
        Notify.success('Availability updated successfully', 'Updated', undefined, 5_000);
      } else {
        Notify.error('Availability could not be updated. Please try again later', 'Not Updated');
      }
    },
    [availabilityUpdate, jobId, updateAvailability, user?.token]
  );

  const handleDeleteAvailableDay = useCallback(
    async (itemId: string) => {
      setDeletingId(itemId);
      const res = await deleteAvailability(async () => {
        return PostAsync({
          url: `${apiUrl}/jobs/${jobId}/delete-availability/${itemId || ''}`,
          method: 'DELETE',
          token: user?.token,
        });
      });
      setDeletingId('');
      if (res?.meta?.status === 'success') {
        setInterviewAvailabilities((p) => {
          const updatedAvailabilities = p.filter((a) => a.id !== itemId);
          if (!updatedAvailabilities.length) {
            setShowInterviewForm(true);
          }
          return updatedAvailabilities;
        });
        Notify.success('Availability deleted successfully', 'Deleted', undefined, 5_000);
      } else {
        Notify.error('Availability could not be deleted. Please try again later', 'Not Deleted');
      }
    },
    [deleteAvailability, jobId, user?.token]
  );

  const handleSendInterviewRequest = useCallback(
    async (candidateIds: string[], onSendSuccessful?: () => void) => {
      const res = await sendInterviewRequest(async () => {
        return PostAsync({
          url: `${apiUrl}/jobs/${jobId}/confirm-interview`,
          method: 'POST',
          token: user?.token,
          body: {
            talent_uids: candidateIds,
          },
        });
      });

      if (res?.meta?.status === 'success') {
        if (onSendSuccessful) onSendSuccessful();
        changeTabToInterviews();
        if (window?.freshpaint) {
          window.freshpaint?.track('Company successfully sent an Interview', {});
        }
        Notify.success(
          'The interview details including your availability has been successfully communicated to the talent(s).',
          'Your Interview Request Has Been Sent',
          'center',
          15_000
        );
      } else {
        Notify.error(
          'Interview request could not be sent. Please try again later',
          'Error Sending Request'
        );
      }
    },
    [changeTabToInterviews, jobId, sendInterviewRequest, user?.token]
  );
  useEffect(() => {
    handleGetInitialAvailabilities();
  }, [handleGetInitialAvailabilities]);

  const hookReturn = {
    interviewAvailabilities,
    handleAddAvailableDay,
    handleGetInitialAvailabilities,
    handleDeleteAvailableDay,
    handleUpdateAvailableDay,
    showInterviewForm,
    setShowInterviewForm,
    showUpdateAvailabilityForm,
    availabilityUpdate,
    isDeletingAvailability,
    handleSendInterviewRequest,
    isAddingAvailability,
    isUpdatingAvailability,
    isGettingAvailabilities,
    isSendingInterviewRequest,
    setAvailabilityUpdate,
    deletingId,
  };

  return hookReturn;
}
