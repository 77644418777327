'use client';

/* eslint-disable max-lines */
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { Edit2, Trash2, X } from 'react-feather';
import DialogTransitionChild from '@/components/Modals/modal-transition/DialogTransitionChild';
import { LoaderSpinner } from '@/components/UI';
import useScheduleInterview from '@/modules/Interviews/hooks/useScheduleInterview';
import ScheduleInterviewForm from './components/ScheduleInterviewForm';
import { InterviewAvailability } from './types';
type ScheduleInterviewModalProps = {
  showModal: boolean;
  onCloseDialog: () => void;
  onFinishSchedule?(): void;
  onMeetingReschedule?(update: InterviewAvailability): void;
  selectedForInterview: string[];
};
export default function ScheduleInterviewModal({
  showModal,
  onCloseDialog,
  onFinishSchedule,
  onMeetingReschedule,
  selectedForInterview
}: ScheduleInterviewModalProps) {
  const buttonRef = useRef(null);
  const interviewHook = useScheduleInterview();
  return <>
      <Transition.Root show={showModal} as={Fragment} data-sentry-element="unknown" data-sentry-source-file="ScheduleInterviewModal.tsx">
        <Dialog as="div" className="relative z-10" onClose={onCloseDialog} initialFocus={buttonRef} data-sentry-element="Dialog" data-sentry-source-file="ScheduleInterviewModal.tsx">
          <DialogTransitionChild data-sentry-element="DialogTransitionChild" data-sentry-source-file="ScheduleInterviewModal.tsx" />
          <div className="scrollbar-primary fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center lg:p-2">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" data-sentry-element="unknown" data-sentry-source-file="ScheduleInterviewModal.tsx">
                <Dialog.Panel className="relative min-h-full w-full transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-[70%] lg:my-[1.5vh] lg:max-h-[95vh] lg:max-w-[50%] 2xl:max-w-[1000px]" data-sentry-element="unknown" data-sentry-source-file="ScheduleInterviewModal.tsx">
                  <div className="flex h-full w-full flex-col">
                    <div className="scrollbar-primary flex flex-1 flex-col justify-between py-5 lg:overflow-y-auto">
                      <div className="">
                        {/* close */}
                        <div className="flex w-full justify-end px-5 md:px-8" ref={buttonRef}>
                          <X className="text-gray-600" size={18} onClick={() => onCloseDialog()} data-sentry-element="X" data-sentry-source-file="ScheduleInterviewModal.tsx" />
                        </div>
                        {/* content */}

                        {/* header */}
                        <div className="mb-1 px-6">
                          <h3 className={classNames('block text-base font-medium text-gray-900')}>
                            Set Your Availability
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Let us know when you are available to accept meetings.
                          </p>
                        </div>

                        {/* body */}
                        <ScheduleInterviewForm onFinishAdd={values => {
                        interviewHook?.handleAddAvailableDay(values);
                      }} onFinishUpdate={values => interviewHook?.handleUpdateAvailableDay(values, onMeetingReschedule)} isAdding={interviewHook.isAddingAvailability} updating={interviewHook.isUpdatingAvailability} onCancelUpdate={() => interviewHook.setAvailabilityUpdate(null)} initialFormValues={interviewHook.availabilityUpdate} showForm={Boolean(interviewHook?.showInterviewForm || interviewHook.availabilityUpdate)} handleShowForm={() => interviewHook?.setShowInterviewForm(true)} data-sentry-element="ScheduleInterviewForm" data-sentry-source-file="ScheduleInterviewModal.tsx" />
                      </div>

                      {!interviewHook.availabilityUpdate && <div className="p-6">
                          {interviewHook?.isGettingAvailabilities ? <LoaderSpinner className="-mx-[20px] -ml-[12px] h-4 w-4 justify-center" /> : Boolean(interviewHook?.interviewAvailabilities?.length) && <>
                                <h3 className={classNames('ml-2 block text-base font-medium text-gray-900')}>
                                  Available Days
                                </h3>
                                {interviewHook?.interviewAvailabilities.map(item => {
                          return <div className="mt-4 flex justify-between rounded border border-gray-200 p-4" key={item?.id || Math.random().toString()}>
                                      <div className="mr-2 w-3/4 space-y-1 break-words text-sm text-gray-700">
                                        <p className="font-medium text-gray-800">{item.day}</p>
                                        <p>{item.period}</p>
                                        <p>{item.duration} mins</p>
                                        {item.meetingLink && <>
                                            <p className="font-medium text-gray-800">
                                              Meeting Link:
                                            </p>
                                            <p className="">{item.meetingLink}</p>
                                          </>}
                                        {item.meetingLocation && <>
                                            <p className="font-medium text-gray-800">
                                              Meeting Location:
                                            </p>
                                            <p className="">{item.meetingLocation}</p>
                                          </>}
                                      </div>
                                      <div className="">
                                        <div className="flex items-center justify-between gap-x-3">
                                          <Edit2 role="button" className="my-1 text-gray-700" size={17} onClick={() => interviewHook?.showUpdateAvailabilityForm(item.id)} />
                                          <div className="flex items-start">
                                            {interviewHook?.isDeletingAvailability && interviewHook?.deletingId === item.id && <LoaderSpinner className="-mx-[17px] h-3 w-3 px-0 pr-0" />}
                                            {(!interviewHook?.isDeletingAvailability || interviewHook?.deletingId !== item.id) && <Trash2 className="my-1 text-gray-700" role="button" size={17} onClick={() => interviewHook?.handleDeleteAvailableDay(item.id)} />}
                                          </div>
                                        </div>
                                      </div>
                                    </div>;
                        })}
                              </>}
                        </div>}
                    </div>
                    {Boolean(!interviewHook.availabilityUpdate && selectedForInterview.length && selectedForInterview?.every(s => Boolean(s))) && <div className="position-fixed bottom-0 flex flex-col space-y-3 p-6 md:flex-row md:space-x-3 md:space-y-0">
                        <button type="button" className="btn-secondary flex flex-1 justify-center" onClick={onCloseDialog}>
                          Cancel
                        </button>
                        <button type="button" disabled={interviewHook.isSendingInterviewRequest} className={`btn-primary flex-1`} onClick={() => interviewHook.handleSendInterviewRequest(selectedForInterview, () => {
                      onCloseDialog();
                      if (onFinishSchedule) onFinishSchedule();
                    })}>
                          {interviewHook.isSendingInterviewRequest ? 'Sending...' : 'Confirm'}
                        </button>
                      </div>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div />
    </>;
}