import * as yup from 'yup';
import { ObjectShape } from 'yup';
import { ScheduleInterviewFormInputs } from '@/validation/types';

const urlVerify = async (value: string | undefined) => {
  const validator = (await import('validator')).default;
  if (value && validator.isURL(value)) {
    return true;
  }
  return false;
};
const scheduleInterviewFormValidationShape: Record<keyof ScheduleInterviewFormInputs, unknown> = {
  isInPerson: yup.bool().required('Please provide this'),
  day: yup.string().required('Please, provide your interview day!'),
  from: yup.string().required('Please, provide the proposed start time of interview!'),
  to: yup.string().required('Please, provide the proposed end time of interview!'),
  duration: yup.string().required('Please, provide the interview duration!'),
  meetingLink: yup.string().when('isInPerson', ([value]) => {
    if (value === false) {
      return yup
        .string()
        .required('Please, provide the interview meeting link!')
        .test('meetingLink', `Meeting link must be a valid url`, urlVerify);
    }
    return yup.string();
  }),
  meetingLocation: yup.string().when('isInPerson', ([value]) => {
    if (value === true) {
      return yup.string().required('Please, provide the interview meeting location!');
    }
    return yup.string();
  }),
};
// eslint-disable-next-line import/prefer-default-export
export const ScheduleInterviewFormValidationSchema = yup
  .object()
  .shape(scheduleInterviewFormValidationShape as ObjectShape);
