'use client';

/* eslint-disable max-lines */
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { InferProps } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { PlusCircle } from 'react-feather';
import { InterviewAvailability } from '@/components/Modals/jobs/schedule-interview/types';
import { scheduleInterviewFormFields } from '@/constants/forms/schedule-interview';
import { dateFormat } from '@/utils/date.helper';
import { SelectMenuPrimary, TextInputPrimary } from '@/ui';
import { ScheduleInterviewFormValidationSchema } from '@/validation/schedule-interview';
import { ScheduleInterviewFormInputs } from '@/validation/types';
export const ScheduleInterviewFormProps = {};
const getTime = (date?: Date) => date && new Date(date).getTime() ? new Date(date).toLocaleTimeString('en-Us', {
  hour12: false
}) : '';
function ScheduleInterviewForm({
  onFinishAdd,
  onFinishUpdate,
  showForm,
  handleShowForm,
  initialFormValues,
  onCancelUpdate,
  updating,
  isAdding
}: InferProps<typeof ScheduleInterviewFormProps> & {
  onFinishAdd: (formResult: ScheduleInterviewFormInputs) => void;
  onFinishUpdate: (formResult: ScheduleInterviewFormInputs) => void;
  onCancelUpdate(): void;
  updating: boolean;
  isAdding: boolean;
  showForm: boolean;
  handleShowForm: () => void;
  initialFormValues: InterviewAvailability | null;
}) {
  const fromTime = getTime(initialFormValues?.startDate);
  const toTime = getTime(initialFormValues?.endDate);
  const initialValues: ScheduleInterviewFormInputs = {
    isInPerson: initialFormValues?.isInPerson || false,
    day: initialFormValues?.day && dateFormat(new Date(initialFormValues?.day)).dateWithHyphenYearFirst || '',
    from: fromTime || '',
    to: toTime || '',
    duration: initialFormValues?.duration?.toString() || '',
    meetingLink: initialFormValues?.meetingLink || '',
    meetingLocation: initialFormValues?.meetingLocation || ''
  };
  const selectOptions: Record<string, Array<string | string[]>> = useMemo(() => ({
    duration: Array.from({
      length: 4
    }).map((x, i) => [`${(i + 1) * 15} mins`, ((i + 1) * 15).toString()]) || []
  }), []);
  const onSubmitForm = useCallback(async (values: ScheduleInterviewFormInputs) => {
    if (!initialFormValues) {
      return onFinishAdd(values);
    }
    return onFinishUpdate(values);
  }, [initialFormValues, onFinishAdd, onFinishUpdate]);
  return <Formik enableReinitialize initialValues={initialValues} validationSchema={ScheduleInterviewFormValidationSchema} onSubmit={onSubmitForm} data-sentry-element="Formik" data-sentry-component="ScheduleInterviewForm" data-sentry-source-file="ScheduleInterviewForm.tsx">
      {({
      submitForm,
      values
    }) => <Form className="mt-6 space-y-4 px-6">
          {showForm && scheduleInterviewFormFields?.map(({
        id,
        autoComplete,
        type,
        labelFor,
        labelText,
        labelDescription,
        placeholder,
        required
      }, index) => {
        const isMeetingLink = id === 'meetingLink';
        const isMeetingLocation = id === 'meetingLocation';
        if (['time', 'select'].some(tp => tp === type)) {
          const shortInputs = scheduleInterviewFormFields.filter((_, idx) => idx === index || idx === index + 1);
          const isFrom = id === 'from';
          const isTo = id === 'to';
          if (isTo) return null;
          if (isFrom) {
            return <div key={id} className="mt-4 grid grid-cols-1 justify-between gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        {shortInputs.map(shortInput => {
                return <TextInputPrimary key={shortInput.id} useFormik value={values[shortInput.id] as string} {...{
                  ...shortInput
                }} />;
              })}
                      </div>;
          }
          return <SelectMenuPrimary key={id} useFormik value={values[id] as string} {...{
            id,
            autoComplete,
            type,
            labelFor,
            labelDescription,
            labelText,
            defaultValue: placeholder,
            // values[id] ||
            required
          }} options={selectOptions[id as keyof ScheduleInterviewFormInputs] as string[]} />;
        }
        if (type === 'checkbox') {
          return <label key={id} htmlFor={id} className="flex items-center text-sm font-medium text-gray-500">
                      <Field id={id} name={id} type="checkbox" className="mr-3 h-4 w-4 rounded border border-gray-400" />
                      {labelText}
                    </label>;
        }
        if (isMeetingLink && values.isInPerson || isMeetingLocation && !values.isInPerson) return null;
        return <div key={id}>
                    <TextInputPrimary useFormik type={type} value={values[id] as string} {...{
            id,
            autoComplete,
            labelText,
            labelFor,
            placeholder,
            required
          }} />
                    {isMeetingLink && <p className="block text-sm font-medium text-gray-500">
                        The meeting link entered here will appear on the confirmation page and in
                        the calendar to the invited talents.
                      </p>}
                    {isMeetingLocation && <p className="block text-sm font-medium text-gray-500">
                        The address entered here will appear on the confirmation page and in the
                        calendar to the invited talents.
                      </p>}
                  </div>;
      })}
          {!initialFormValues && <div className="mt-3 flex items-center justify-end">
              {!showForm && <PlusCircle className={classNames('h-4 w-4 text-primary-default')} onClick={() => null} />}
              <button disabled={isAdding} type="button" className={classNames(!showForm ? 'ml-3 block text-sm font-medium text-primary-default' : 'btn-primary ml-auto flex w-auto flex-shrink self-end')} onClick={() => {
          if (showForm) {
            return submitForm();
          }
          return handleShowForm();
        }}>
                {isAdding ? 'Adding Slot...' : 'Add Time Slot'}
              </button>
            </div>}
          {initialFormValues && <div className="position-fixed bottom-0 flex flex-col space-y-3 py-6 md:flex-row md:space-x-3 md:space-y-0">
              <button type="button" className="btn-secondary flex flex-1 justify-center" onClick={onCancelUpdate}>
                Cancel
              </button>
              <button type="button" disabled={updating} className={`btn-primary flex-1 ${updating ? 'opacity-75' : ''}`} onClick={() => submitForm()}>
                {updating ? 'Updating...' : 'Update'}
              </button>
            </div>}
        </Form>}
    </Formik>;
}
export default ScheduleInterviewForm;